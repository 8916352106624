import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../NavBar";
import Drawer from "@mui/material/Drawer";
import makeStyles from '@mui/styles/makeStyles';
import { myFetch, feathersClient, refreshGqlClient } from "../../../attach-client";
import PinVerificationModal from "./pinVerificationModal";
import AnimalCaptchaVerificationModal from "./animalCaptchaVerificationModal";

import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";
import {
  CampaignSelector,
  NavMenu,
  SonityProfileSelector,
  UserProfile
} from "./Drawer/drawerItems";
import CustomSnackbar from "../CustomSnackbar";
import drawerMenus from "./Drawer/drawerMenus";
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  list: {
    width: "100%"
  }
}));

const MainLayout = ({ children }) => {
  /* TODO NOTE
   *  Experiment with top-level Contexts here -- such as useCurrentChats, useEventSubs
   *  Prioritize useCurrentChats2 for a Chats context provider --> so we can have chat notifications on all pages
   */

  const user = feathersClient.get("user");
  const history = useHistory();

  let mainLayoutCtx = useMainLayoutContext();
  const { useDriversHook, useMessageHook, useCampaignStatusesHook } = mainLayoutCtx;

  const {
    sonityAccounts,
    selectedSonityAccount,
    sonityProfiles,
    //drivers,
    pinVerification,
    pinVerificationPayload,
    animalCaptchaVerification,
    animalCaptchaVerificationPayload,
    selectedSonityProfile,
    selectedCampaignDefinition,
    campaignDefinitions
  } = mainLayoutCtx.state;

  const {
    setAnimalCaptchaVerification,
    setPinVerification,
    //setDrivers,
    setSelectedSonityProfile,
    setSelectedCampaignDefinition,
    setMainLayoutState
  } = mainLayoutCtx.stateFunctions;

  const [message, { updateMessage, hideMessage }] = useMessageHook;
  const [{ statuses }, { fetchStatuses }] = useCampaignStatusesHook;
  const [{ drivers }, { fetchStats, setDrivers }] = useDriversHook;

  const classes = useStyles();
  const [sidenavOpened, setSidenavOpened] = useState(false);
  const [hasDismissedCaptchaModal, setHasDismissedCaptchaModal] = useState(false)
  const hideAppBar = !useMediaQuery("(min-width:960px)");

  const handleOpenNavigation = () => {
    setSidenavOpened(true);
  };

  const handleCloseNavigation = () => {
    setSidenavOpened(false);
  };

  const handleRouteChange = () => {
    const currentPath = history.location.pathname;
    // Check if the current route starts with "/portal/"
    if (selectedSonityAccount && selectedSonityAccount.selenium_endpoint) {
      console.log("[MainLayout]handleRouteChange::fetchStats")
      fetchStats()
    }
    fetchStatuses();
  }



  React.useEffect(() => {
    if (!user) {
      //user signedout
      return
    }
    if (selectedSonityAccount && selectedSonityAccount.selenium_endpoint) {
      console.log("[mainLayout]fetchStats after user/account change");
      fetchStats();
    }
    return () => {
      // alert("")
      // //this must happen for there to be a fresh useEventSubs when logging back in
      // refreshGqlClient()
    }

  }, [user, selectedSonityAccount]);



  useEffect(() => {
    // Add a listener for route changes
    const unblock = history.listen(handleRouteChange);

    return () => {
      // Clean up the listener when the component unmounts
      unblock();
    };
  }, [user, selectedSonityAccount, history]);

  return (
    <div>
      <NavBar
        openNavigation={() => !sidenavOpened ? handleOpenNavigation() : handleCloseNavigation()}
        user={user}
        drivers={drivers}
        sonityProfiles={sonityProfiles}
        selectedSonityProfile={selectedSonityProfile}
        selectedCampaignDefinition={selectedCampaignDefinition}
        hideAppBar={hideAppBar}
      />

      {pinVerification && <PinVerificationModal
        user={user}
        payload={pinVerificationPayload}
        open={pinVerification}
        handleClose={setPinVerification}
      />}
      {/* Disabled -- Driver sends MS Teams notification instead */}
      {false && !hasDismissedCaptchaModal && animalCaptchaVerification && <AnimalCaptchaVerificationModal
        user={user}
        payload={animalCaptchaVerificationPayload}
        open={animalCaptchaVerification}
        handleClose={() => {
          setAnimalCaptchaVerification();
          setHasDismissedCaptchaModal(true)
        }}
      />}
      {children}
      <Drawer
        open={sidenavOpened}
        onClose={handleCloseNavigation}
        PaperProps={{ style: { width: "70%" } }}
      >
        <div
          className={classes.list}
          role="presentation"
          // onClick={handleCloseNavigation}
          onKeyDown={handleCloseNavigation}
        >
          {/* Profile Selector  */}
          <SonityProfileSelector
            selectedSonityProfile={selectedSonityProfile}
            sonityProfiles={sonityProfiles}
            setSelectedSonityProfile={setSelectedSonityProfile}
          ></SonityProfileSelector>

          {/* Menus   */}
          {drawerMenus.map((menu, i) => {
            let disableMenu = menu.name == "chats" && !selectedSonityProfile;

            return (
              <NavMenu
                key={`menu-${i}`}
                name={menu.name}
                title={menu.title}
                destination={menu.destination}
                setMainLayoutState={setMainLayoutState}
                disableMenu={disableMenu}
                handleClickMenu={() => {
                  handleCloseNavigation()
                }}
              />
            );
          })}

          {/* Campaign Selector  */}

          <div className=" border-b border-gray-300 ">

          </div>

          <CampaignSelector
            campaignDefinitions={campaignDefinitions}
            selectedCampaignDefinition={selectedCampaignDefinition}
            setSelectedCampaignDefinition={setSelectedCampaignDefinition}
            handleCampaignClick={() => {
              handleCloseNavigation()
            }}
          />



        </div>
      </Drawer>
      <CustomSnackbar
        autoHideDuration={2000}
        variant={message.variant}
        message={message.content}
        open={message.isShown}
        onClose={() => {
          hideMessage(message.variant);
        }}
      />
    </div>
  );
};

export default MainLayout;
