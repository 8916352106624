import React, { createContext, useContext, useEffect, useState } from "react";
import { useReducer } from "react";
import { useCampaignDefinitions } from "../../hooks/useCampaignDefinitions";
import { useDrivers } from "../../hooks/useDrivers";
import { useEvents } from "../../hooks/useEvents";
import useEventSubs from "../../hooks/useEventSubs";
import { useMessage } from "../../hooks/useMessage";
import useSonityAccounts from "../../hooks/useSonityAccounts";
import useSonityProfiles from "../../hooks/useSonityProfiles";
import { combinateInitialState, originalInitialState } from "./mainLayoutInitialState";
import mainLayoutReducer from "./mainLayoutReducer";
import mainLayoutStateFuncs from "./mainLayoutStateFuncs";
import { useAuthContext } from "../AuthState/AuthContextProvider";
import useCampaignStatuses from "../../hooks/useCampaignStatuses";
import { useHistory } from "react-router-dom";

const mainLayoutContext = createContext();

/**
 * @name MainLayoutProvider
 * @type component
 * @desc Provide system wide state, hooks and state functions
 * @prop {[Component]} children - Children components that will use this provider
 * @returns A provider
 */

export const MainLayoutProvider = ({ children }) => {
  const authCtx = useAuthContext()
  const { user } = authCtx
  const history = useHistory()


  const [state, dispatch] = useReducer(mainLayoutReducer, combinateInitialState);


  const mainLayoutStateFunctions = mainLayoutStateFuncs(state, dispatch);

  const [message, { updateMessage, hideMessage }] = useMessage();
  const [{ statuses, statusesLoading, statusesError }, { fetchStatuses }] = useCampaignStatuses()

  const useSonityAccountsHook = useSonityAccounts(
    user,
    updateMessage,
    state,
    mainLayoutStateFunctions
  );

  const useSonityProfilesHook = useSonityProfiles(
    state,
    mainLayoutStateFunctions,
    updateMessage
  );

  const useCampaignDefinitionsHook = useCampaignDefinitions(
    state ? state.selectedSonityProfile : null,
    updateMessage,
    state,
    mainLayoutStateFunctions
  );

  const useDriversHook = useDrivers(user, state, updateMessage);
  const [{drivers}, {setDrivers}] = useDriversHook;

  const useEventsHook = useEvents();

  const sessionEventsSubsHook = useEventSubs(
    user,
    state,
    mainLayoutStateFunctions,
    setDrivers
  );



  let value = {
    state,
    dispatch,
    user,
    history,
    stateFunctions: mainLayoutStateFunctions,
    useMessageHook: [message, { updateMessage, hideMessage }],
    useSonityAccountsHook,
    useSonityProfilesHook,
    useCampaignStatusesHook: [{ statuses, statusesLoading, statusesError }, { fetchStatuses }],
    useCampaignDefinitionsHook: useCampaignDefinitionsHook,
    useEventsHook: useEventsHook,
    sessionEventsSubsHook: sessionEventsSubsHook,
    useDriversHook: useDriversHook
  };

  // React.useState(() => {
  //   mainLayoutStateFunctions.setSelectedSonityProfile(null)
  // }, [user])

  useEffect(() => {
    //console.log("ROUTE CHANGE", history);
    if (!statuses || statuses.length == 0) fetchStatuses()

  }, [history, statuses])

  useEffect(() => {
    // or initialize the service here if the process is async


    return () => {
      // cleanup unsubscribe from service
      mainLayoutStateFunctions.setMainLayoutState(originalInitialState)
    };
  }, []);


  console.log("[MainLayoutProvider] Main Layout state: ", state);

  return (
    <mainLayoutContext.Provider value={value}>
      {children}
    </mainLayoutContext.Provider>
  );
};

/**
 * @name useMainLayoutContext
 * @type function
 * @desc Return a Main Layout Context value which is an object
 * @returns Main Layout context
 */
export const useMainLayoutContext = () => {
  return useContext(mainLayoutContext);
};
